import * as echarts from 'echarts'
import type { EChartsOption } from 'echarts'
import { fetchDiskMetrics } from "@/api/detail.api"
import { formatDateTime } from './time.utils'
import { debounce, type DebouncedFunc } from 'lodash'

interface DiskData {
    name: string
    mountPoint: string
    data: [number, number][]
}

export class DiskChartManager {
    private chart: echarts.ECharts
    private loading: boolean
    private hostName: string
    private currentData: Map<string, DiskData> = new Map() // key: name_mountPoint
    private currentRange = {
        start: new Date(),
        end: new Date()
    }
    private debouncedLoadData: DebouncedFunc<(start: Date, end: Date) => void>
    private colors = [
        '#5470c6', '#91cc75', '#fac858', '#ee6666',
        '#73c0de', '#3ba272', '#fc8452', '#9a60b4'
    ]

    constructor(elementId: string, hostname: string) {
        const element = document.getElementById(elementId)
        if (!element) throw new Error('element not found')
        
        this.chart = echarts.init(element)
        this.loading = false
        this.hostName = hostname
        this.debouncedLoadData = debounce(this.loadData.bind(this), 500)
        this.initChart()
    }

    private formatBytes(bytes: number): number {
        return Number((bytes / (1024 * 1024 * 1024)).toFixed(2));
    }

    private calculateYAxisRange(series: any[]): { min: number; max: number } {
        let min = Infinity
        let max = -Infinity

        series.forEach(serie => {
            serie.data.forEach((point: [number, number]) => {
                min = Math.min(min, point[1])
                max = Math.max(max, point[1])
            })
        })

        // 如果没有数据，返回默认范围
        if (min === Infinity || max === -Infinity) {
            return { min: 0, max: 100 }
        }

        // 为了让图表更美观，稍微扩大范围
        const range = max - min
        min = Math.max(0, min - range * 0.1)
        max = max + range * 0.1

        return { min, max }
    }

    private initChart() {
        const option: EChartsOption = {
            color: this.colors,  // 使用预定义的颜色
            tooltip: {
                trigger: 'axis',
                formatter: (params: any) => {
                    if (Array.isArray(params)) {
                        const time = new Date((params[0].value as number[])[0]).toUTCString()
                        let result = `${time}<br/>`
                        params.forEach(param => {
                            const value = (param.value as number[])[1]
                            if (value >= 1) {
                                result += `${param.marker}${param.seriesName}: ${value.toFixed(1)} GB<br/>`
                            } else {
                                result += `${param.marker}${param.seriesName}: ${(value * 1024).toFixed(1)} MB<br/>`
                            }
                        })
                        return result
                    }
                    return ''
                }
            },
            legend: {
                data: [],
                type: 'scroll',
                orient: 'horizontal',
                top: 0
            },
            grid: {
                top: 50,
                right: 20,
                bottom: 60,
                left: 60
            },
            xAxis: {
                type: 'time',
                splitLine: {
                    show: false,
                }
            },
            yAxis: {
                type: 'value',
                name: '磁盘使用',
                splitNumber: 6,  // 将 Y 轴分成 6 段
                splitLine: {
                    show: true
                },
                axisLabel: {
                    formatter: (value: number) => {
                        const isMobile = window.innerWidth < 768;
                        if (value >= 1) {
                            return value.toFixed(1) + (isMobile ? 'G' : ' GB');
                        }
                        return (value * 1024).toFixed(1) + (isMobile ? 'M' : ' MB');
                    }
                }
            },
            dataZoom: [
                {
                    type: 'inside',
                    start: 0,
                    end: 100,
                    minSpan: 1
                },
                {
                    type: 'slider',
                    start: 0,
                    end: 100,
                    minSpan: 1
                }
            ],
            series: []
        }

        this.chart.setOption(option)

        // 监听缩放事件
        this.chart.on('datazoom', (params: any) => {
            if (this.loading || !this.chart || this.currentData.size === 0) return

            console.log('DataZoom triggered:', params)

            // 获取当前数据的时间范围
            const dataTimeRange = this.getDataTimeRange()
            
            // 从当前的 option 中获取缩放范围
            const option = this.chart.getOption()
            const dataZoom = option.dataZoom as any[]
            if (!dataZoom || !dataZoom[0]) {
                console.warn('No dataZoom found in options')
                return
            }

            const { start, end } = dataZoom[0]
            if (typeof start !== 'number' || typeof end !== 'number') {
                console.warn('Invalid zoom range:', { start, end })
                return
            }

            // 计算实际的时间戳
            const timeRange = dataTimeRange.end - dataTimeRange.start
            const startTime = dataTimeRange.start + (timeRange * start / 100)
            const endTime = dataTimeRange.start + (timeRange * end / 100)

            console.log('Calculated time range:', {
                start: new Date(startTime).toUTCString(),
                end: new Date(endTime).toUTCString(),
                percentRange: { start, end }
            })

            // 检查是否需要加载新数据
            if (this.shouldLoadNewData(startTime, endTime)) {
                this.debouncedLoadData(new Date(startTime), new Date(endTime))
            } else {
                // 如果不需要加载新数据，只更新显示范围
                this.updateVisibleData(startTime, endTime)
            }
        })
    }

    private getDataTimeRange() {
        // 找到所有数据中的最早和最晚时间
        let start = Date.now()
        let end = 0

        for (const diskData of this.currentData.values()) {
            if (diskData.data.length > 0) {
                start = Math.min(start, diskData.data[0][0])
                end = Math.max(end, diskData.data[diskData.data.length - 1][0])
            }
        }

        return { start, end }
    }

    private updateVisibleData(startTime: number, endTime: number) {
        if (!this.chart) return

        const series: any[] = []
        const legendData: string[] = []

        this.currentData.forEach((diskData, key) => {
            const visibleData = diskData.data.filter(point =>
                point[0] >= startTime && point[0] <= endTime
            )

            const displayName = `${diskData.name} (${diskData.mountPoint})`
            legendData.push(displayName)

            // 设置不同的样式
            const isUsed = key.endsWith('_used')
            series.push({
                name: displayName,
                type: 'line',
                showSymbol: false,
                data: visibleData,
                emphasis: {
                    focus: 'series'
                },
                lineStyle: {
                    type: isUsed ? 'solid' : 'dashed'  // 已用空间实线，可用空间虚线
                },
                areaStyle: isUsed ? {
                    opacity: 0.1
                } : undefined  // 已用空间添加区域填充
            })
        })

        console.log('Updating chart with series:', {
            legendData,
            seriesCount: series.length,
            firstSeries: series[0]?.name
        })

        // 计算 Y 轴范围
        const { min, max } = this.calculateYAxisRange(series)

        this.chart.setOption({
            legend: {
                data: legendData
            },
            yAxis: {
                type: 'value',
                name: '磁盘使用',
                min: min,
                max: max,
                splitNumber: 6,
                splitLine: {
                    show: true
                },
                axisLabel: {
                    formatter: (value: number) => {
                        const isMobile = window.innerWidth < 768;
                        if (value >= 1) {
                            return value.toFixed(1) + (isMobile ? 'G' : ' GB');
                        }
                        return (value * 1024).toFixed(1) + (isMobile ? 'M' : ' MB');
                    }
                }
            },
            series: series
        })
    }

    private shouldLoadNewData(startTime: number, endTime: number): boolean {
        const currentStart = this.currentRange.start.getTime()
        const currentEnd = this.currentRange.end.getTime()

        // 如果请求的时间范围完全在当前数据范围内，则不需要加载新数据
        if (startTime >= currentStart && endTime <= currentEnd) {
            return false
        }

        return true
    }

    private calculateInterval(start: Date, end: Date): string {
        const hours = (end.getTime() - start.getTime()) / (1000 * 60 * 60)
        if (hours > 24 * 7) return '1hour'  // 超过一周用小时
        if (hours > 24) return '30min'      // 超过一天用30分钟
        if (hours > 6) return '5min'        // 超过6小时用5分钟
        if (hours > 1) return '1min'        // 超过1小时用1分钟
        return '5sec'                       // 小于1小时用5秒
    }

    async loadData(start: Date, end: Date) {
        if (this.loading || !start || !end || isNaN(start.getTime()) || isNaN(end.getTime())) {
            console.warn('Invalid date range or loading in progress:', { start, end, loading: this.loading })
            return
        }

        try {
            this.loading = true
            if (this.chart) {
                this.chart.showLoading()
            }

            const interval = this.calculateInterval(start, end)
            console.log('Loading data with params:', {
                startTime: start.toUTCString(),
                endTime: end.toUTCString(),
                interval,
                hostName: this.hostName
            })

            const [usedData, freeData] = await fetchDiskMetrics(
                this.hostName,
                formatDateTime(start),
                formatDateTime(end),
                interval
            )

            console.log('Received disk data:', { usedData, freeData })

            // 按照 name 和 mount_point 分组处理数据
            const groupedData = new Map<string, DiskData>()
            
            // 处理已用空间数据
            usedData.forEach(item => {
                if (!item.name || !item.mount_point) {
                    console.warn('Invalid disk data:', item)
                    return
                }

                const key = `${item.name}_${item.mount_point}_used`
                if (!groupedData.has(key)) {
                    groupedData.set(key, {
                        name: `${item.name} (已用)`,
                        mountPoint: item.mount_point,
                        data: []
                    })
                }
                
                const diskData = groupedData.get(key)!
                diskData.data.push([
                    new Date(item.timestamp).getTime(),
                    this.formatBytes(item.value)
                ])
            })

            // 处理可用空间数据
            freeData.forEach(item => {
                if (!item.name || !item.mount_point) {
                    console.warn('Invalid disk data:', item)
                    return
                }

                const key = `${item.name}_${item.mount_point}_free`
                if (!groupedData.has(key)) {
                    groupedData.set(key, {
                        name: `${item.name} (可用)`,
                        mountPoint: item.mount_point,
                        data: []
                    })
                }
                
                const diskData = groupedData.get(key)!
                diskData.data.push([
                    new Date(item.timestamp).getTime(),
                    this.formatBytes(item.value)
                ])
            })

            console.log('Grouped disk data:', Array.from(groupedData.entries()))

            // 合并新旧数据
            groupedData.forEach((newDiskData, key) => {
                const existingData = this.currentData.get(key)
                if (existingData) {
                    // 合并并去重
                    const allData = [...existingData.data, ...newDiskData.data]
                    newDiskData.data = Array.from(new Map(allData.map(item => [item[0], item])).values())
                        .sort((a, b) => a[0] - b[0])
                }
                this.currentData.set(key, newDiskData)
            })

            this.updateVisibleData(start.getTime(), end.getTime())
            this.currentRange = { start, end }

        } catch (error) {
            console.error('Error loading data:', error)
        } finally {
            this.loading = false
            if (this.chart) {
                this.chart.hideLoading()
            }
        }
    }

    async initLoad() {
        const end = new Date()
        // 默认加载最近24小时的数据
        const start = new Date(end.getTime() - 24 * 60 * 60 * 1000)
        await this.loadData(start, end)
    }

    resize() {
        if (this.chart) {
            this.chart.resize()
        }
    }

    dispose() {
        if (this.debouncedLoadData) {
            this.debouncedLoadData.cancel()
        }
        if (this.chart) {
            this.chart.dispose()
            this.chart = null as any
        }
    }
}