<template>
  <div class="w-11/12 mx-auto">
    <div class="flex flex-col md:flex-row pt-6 items-start md:items-center gap-4 mb-4">
      <n-select
        v-model:value="currentHost"
        :options="hostOptions"
        @update:value="handleHostChange"
        class="w-full md:w-64"
      />
      <n-button @click="goBack" class="w-full md:w-auto">返回首页</n-button>
    </div> 
  </div>
  <h1 class="mt-4 px-4 md:pl-16">Hostname: {{ id }}</h1>
  <div class="w-11/12 h-[300px] md:h-[600px] mt-[30px] md:mt-[100px] mx-auto">
    <h2 class="text-lg md:text-xl mb-2 md:mb-4">内存数据</h2>
    <div id="memory-chart" class="w-full h-full">
    </div>
  </div>
  <div class="w-11/12 h-[300px] md:h-[600px] mt-[50px] md:mt-[200px] mx-auto">
    <h2 class="text-lg md:text-xl mb-2 md:mb-4">磁盘数据</h2>
    <div id="disk-chart" class="w-full h-full">
    </div>
  </div>
  <div class="w-11/12 h-[300px] md:h-[600px] mt-[50px] md:mt-[200px] mx-auto">
    <h2 class="text-lg md:text-xl mb-2 md:mb-4">CPU负载</h2>
    <div id="cpu-chart" class="w-full h-full">
    </div>
  </div>
  <div class="w-11/12 h-[300px] md:h-[600px] mt-[50px] md:mt-[200px] mx-auto">
    <h2 class="text-lg md:text-xl mb-2 md:mb-4">数据库进程</h2>
    <div id="db-process-chart" class="w-full h-full">
    </div>
  </div>
  <div class="w-11/12 h-[300px] md:h-[600px] mt-[50px] md:mt-[200px] mx-auto">
    <h2 class="text-lg md:text-xl mb-2 md:mb-4">网络入站</h2>
    <div id="network-in-chart" class="w-full h-full">
    </div>
  </div>
  <div class="w-11/12 h-[300px] md:h-[600px] mt-[50px] md:mt-[200px] mx-auto">
    <h2 class="text-lg md:text-xl mb-2 md:mb-4">网络出站</h2>
    <div id="network-out-chart" class="w-full h-full">
    </div>
  </div>
  <div class="w-11/12 mt-[50px] md:mt-[200px] mx-auto">
    <h2 class="text-lg md:text-xl mb-2 md:mb-4">数据库进程列表</h2>
    <n-button type="primary" class="w-full md:w-auto mb-4" :loading="loadingDbList" @click="refreshDbProcessList">
      刷新
    </n-button>
    <div class="overflow-x-auto -mx-4 md:mx-0">
      <div class="min-w-[800px] px-4 md:px-0">
        <n-data-table 
          class="mb-24"
          :columns="dblistColumns" 
          :data="dblistData"
          :min-height="250"
          :scroll-x="1360"
          :row-height="36"
          :header-height="36"
          :bordered="false"
          :single-line="false"
        ></n-data-table>
      </div>
    </div>
  </div>
  <div class="w-11/12 mt-[50px] md:mt-[200px] mx-auto">
    <h2 class="text-lg md:text-xl mb-2 md:mb-4">后台任务</h2>
    <div class="flex flex-col md:flex-row gap-4 mb-4">
      <n-button class="w-full md:w-auto" type="primary" @click="showAddBackendProcessModal = true">
        添加后台任务
      </n-button>
      <n-button class="w-full md:w-auto" :loading="loading" type="info" @click="refreshBackendProcesses">
        刷新任务状态
      </n-button>
    </div>
    <n-modal v-model:show="showAddBackendProcessModal">
      <n-card
        style="width: 90vw; max-width: 600px;"
        title="添加后台任务"
        :bordered="false"
        size="huge"
      >
        <template #footer>
          <n-button type="primary" @click="addProcess" class="mr-4">添加</n-button>
          <n-button @click="cancel">取消</n-button>
        </template>
        <n-form>
          <n-form-item label="任务名称" required>
          <n-input v-model:value="newBackendProcess.process_name" />
        </n-form-item>
        <n-form-item label="可执行程序">
          <n-input v-model:value="newBackendProcess.program" placeholder="请输入可执行程序路径, 例如 /usr/bin/perl" />
        </n-form-item>
        <n-form-item label="参数">
          <n-tooltip>
            <template #trigger>
              <n-icon :component="HelpCircleOutline" />
            </template>
            <div>参数，务必看好顺序</div>
          </n-tooltip>
          <n-dynamic-tags v-model:value="newBackendProcess.args" />
        </n-form-item>
        <n-form-item label="环境变量">
          <n-dynamic-input
            v-model:value="envList"
            preset="pair"
            key-placeholder="环境变量名"
            value-placeholder="环境变量值"
          />
        </n-form-item>
        <n-form-item label="工作目录">
          <n-input v-model:value="newBackendProcess.cwd" placeholder="请输入工作目录, 例如 /home/user" />
        </n-form-item>
        <n-form-item label="用户" required>
          <n-input v-model:value="newBackendProcess.user" placeholder="请输入用户名, 例如 root" />
        </n-form-item>
        <n-form-item label="是否自动重启">
          <n-switch v-model:value="newBackendProcess.auto_restart" />
        </n-form-item>
        <n-form-item v-if="newBackendProcess.auto_restart" label="最大重启次数">
          <n-tooltip>
            <template #trigger>
              <n-icon :component="HelpCircleOutline" />
            </template>
            <div>最大重启次数，超过此次数后不再自动重启，0��示不重启, -1表示不限制次数</div>
          </n-tooltip>
          <n-input-number v-model:value="newBackendProcess.max_restarts" />
        </n-form-item>
        <n-form-item v-if="newBackendProcess.auto_restart" label="重启延迟">
          <n-input-number v-model:value="newBackendProcess.restart_delay" />
        </n-form-item>
        <n-form-item v-if="newBackendProcess.auto_restart && newBackendProcess.restart_delay > 0" label="是否开启指数回避">
          <n-switch v-model:value="newBackendProcess.exponential_backoff" />
        </n-form-item>
        <n-form-item label="依赖">
          <n-dynamic-tags v-model:value="newBackendProcess.dependencies" />
        </n-form-item>
        </n-form>
      </n-card>
    </n-modal>
    <div class="overflow-x-auto -mx-4 md:mx-0">
      <div class="min-w-[800px] px-4 md:px-0">
        <n-data-table 
          class="mb-24"
          :columns="columns" 
          :data="data"
          :min-height="250"
          :scroll-x="1360"
          :row-height="36"
          :header-height="36"
          :bordered="false"
          :single-line="false"
        ></n-data-table>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router';
import { nextTick, onMounted, ref, h, computed, watch } from 'vue';
import { ChartManager } from '@/utils/ChartManager';
import { DiskChartManager } from '@/utils/DiskChartManager';
import { CpuChartManager } from '@/utils/CpuChartManager';
import { DbProcessChartManager } from '@/utils/DbProcessChartManager';
import { 
  NDataTable, 
  type DataTableColumns, 
  NModal, 
  NForm, 
  NFormItem, 
  NInput, 
  NInputNumber, 
  NButton, 
  NTooltip, 
  NIcon, 
  NCard,
  NDynamicTags,
  NDynamicInput,
  NSwitch,
  NSelect
 } from 'naive-ui';
import { addBackendProcess, deleteBackendProcess, fetchRunningBackendProcesses, restartBackendProcess, startBackendProcess, stopBackendProcess, updateBackendProcess } from '@/api/detail.api';
import { HelpCircleOutline, CheckmarkCircleOutline, CloseCircleOutline } from '@vicons/ionicons5';
import { formatDateTime } from '@/utils/time.utils';
import { getLastDbProcessList } from '@/api/system-data.api';
import { NetworkChartManager } from '@/utils/NetworkChartManager';
import { useAuthStore } from '@/store/auth.store';
import { storeToRefs } from 'pinia';

const route = useRoute()
const id = ref(route.params.id as string)
const isUpdate = ref(false)
const chartManager = ref<ChartManager>()
const diskChartManager = ref<DiskChartManager>()
const cpuChartManager = ref<CpuChartManager>()
const dbProcessChartManager = ref<DbProcessChartManager>()
const networkInChartManager = ref<NetworkChartManager>()
const networkOutChartManager = ref<NetworkChartManager>()

const authStore = useAuthStore()
const {hosts} = storeToRefs(authStore)

const router = useRouter();
const currentHost = ref(id);

const hostOptions = computed(() => {
  return hosts.value.map(host => ({
    label: host,
    value: host
  }));
});

const handleHostChange = (value: string) => {
  router.push(`/detail/${value}`);
};

const goBack = () => {
  router.push('/');
};

// dynamic input 接收的是 hash array, 这里单独用一个变量接收，发送的时候转换赋值给newBackendProcess.envs
const envList = ref([
  {key: '', value: ''}
]);

const showAddBackendProcessModal = ref(false)
type RunningBackendProcess = Awaited<ReturnType<typeof fetchRunningBackendProcesses>>[number]
const newBackendProcess = ref<Omit<RunningBackendProcess, 'running' | 'last_error' | 'last_exit_code' | 'last_restart_at' | 'created_at' | 'restart_count' | 'last_exited_at' | 'started_at'>>({
  id: 0,
  host_name: id.value,
  process_name: '',
  program: '',
  args: [],
  auto_restart: true,
  max_restarts: 0,
  restart_delay: 0,
  envs: {},
  user: '',
  cwd: '',
  dependencies: [],
  exponential_backoff: false,
})

const columns = ref<DataTableColumns>([
  { title: 'ID', key: 'id', width: 40, fixed: 'left' },
  { title: '任务名称', key: 'process_name', width: 100, fixed: 'left' },
  { 
    title: '状态', 
    key: 'running',
    width: 60,
    fixed: 'left',
    render(row) {
      return row.running ? 
        h(NIcon, { color: '#18a058', size: 24 }, { default: () => h(CheckmarkCircleOutline) }) : 
        h(NIcon, { color: '#d03050', size: 24 }, { default: () => h(CloseCircleOutline) });
    }
  },
  { title: '重启数', key: 'restart_count', width: 70 },
  { title: '退出时间', key: 'last_exited_at', width: 140, render(row) {
    if (!row.last_exited_at) {
      return '无'
    }
    return formatDateTime(new Date(row.last_exited_at as string))
  } },
  { title: '启动时间', key: 'started_at', width: 140, render(row) {
    if (!row.started_at) {
      return '无'
    }
    return formatDateTime(new Date(row.started_at as string))
  } },
  { title: '运行时间', key: 'run_time', width: 100, render(row) {
    if (!row.started_at || !row.last_exited_at) {
      return '无'
    }
    const runTime = (new Date(row.last_exited_at as string).getTime() - new Date(row.started_at as string).getTime()) / 1000
    return runTime + '秒'
  } },
  { title: '退出码', key: 'last_exit_code', width: 70 },
  { title: '错误', key: 'last_error', width: 80 },
  { title: '重启时间', key: 'last_restart_at', width: 140, render(row) {
    if (!row.last_restart_at) {
      return '无'
    }
    return formatDateTime(new Date(row.last_restart_at as string))
  } },
  { title: '程序', key: 'program', width: 200 },
  { title: '参数', key: 'args', width: 200, render(row) {
    return (row.args as string[]).join(', ')
  } },
  { 
    title: '自动重启', 
    key: 'auto_restart',
    width: 96,
    render(row) {
      return row.auto_restart ? 
        h(NIcon, { color: '#18a058', size: 24 }, { default: () => h(CheckmarkCircleOutline) }) : 
        h(NIcon, { color: '#d03050', size: 24 }, { default: () => h(CloseCircleOutline) });
    }
  },
  { title: '最大重启次数', key: 'max_restarts', width: 120 },
  { title: '重启延迟', key: 'restart_delay', width: 96 },
  { 
    title: '环境变量', 
    key: 'envs',
    width: 256,
    render(row) {
      if (!row.envs || Object.keys(row.envs).length === 0) {
        return null;
      }
      return h('div', {
        style: {
          whiteSpace: 'pre-line'  // 换行
        }
      }, Object.entries(row.envs)
          .map(([key, value]) => `${key}=${value}`)
          .join('\n')
      );
    }
  },
  { title: '用户', key: 'user', width: 96 },
  { 
    title: '指数回避', 
    key: 'exponential_backoff',
    width: 96,
    render(row) {
      return row.exponential_backoff ? 
        h(NIcon, { color: '#18a058', size: 24 }, { default: () => h(CheckmarkCircleOutline) }) : 
        h(NIcon, { color: '#d03050', size: 24 }, { default: () => h(CloseCircleOutline) });
    }
  },
  { title: '工作目录', key: 'cwd', width: 256 },
  { 
    title: '依赖', 
    key: 'dependencies',
    width: 256,
    render(row) {
      if (!row.dependencies || (row.dependencies as string[]).length === 0) {
        return '无';
      }
      return (row.dependencies as string[]).join(', ')
    }
  },
  { title: '创建时间', key: 'created_at', width: 176, render(row) {
    return formatDateTime(new Date(row.created_at as string))
  } },
  // 3个按钮：停止、删除、重启
  { 
    title: '操作', 
    key: 'actions', 
    width: 280, 
    fixed: 'right', 
    render(row) {
      return h('div', { class: 'space-x-2' }, [
        h(
          NButton,
          { 
            type: row.running ? 'warning' : 'primary',
            size: 'small',
            onClick: async () => {
              if (row.running) {
                await stopBackendProcess(id.value, row.process_name as string)
              } else {
                await startBackendProcess(id.value, row.process_name as string)
              }
              data.value = await fetchRunningBackendProcesses(id.value)
            }
          },
          { default: () => row.running ? '停止' : '启动' }
        ),
        h(
          NButton,
          { 
            type: 'warning',
            size: 'small',
            onClick: async () => {
              await restartBackendProcess(id.value, row.process_name as string)
              data.value = await fetchRunningBackendProcesses(id.value)
            }
          },
          { default: () => '重启' }
        ),
        h(
          NButton,
          { 
            type: 'error',
            size: 'small',
            onClick: async () => {
              await deleteBackendProcess(id.value, row.process_name as string, row.id as number)
              data.value = await fetchRunningBackendProcesses(id.value)
            }
          },
          { default: () => '删除' }
        ),
        h(
          NButton,
          {
            type: 'info',
            size: 'small',
            onClick: () => {
              isUpdate.value = true
              newBackendProcess.value = row as any;
              showAddBackendProcessModal.value = true
              envList.value = Object.entries(row.envs as Record<string, string>).map(([key, value]) => ({key, value}))
            }
          },
          { default: () => '编辑' }
        )
      ])
    }
  },
])

const data = ref<Awaited<ReturnType<typeof fetchRunningBackendProcesses>>>([])

const dblistColumns = ref<DataTableColumns>([
  { title: 'ID', key: 'id', width: 40, fixed: 'left' },
  { title: '用户', key: 'user', width: 80 },
  { title: '主机', key: 'host', width: 80 },
  { title: '数据库', key: 'db', width: 80 },
  { title: '命令', key: 'command', width: 200 },
  { title: '时间', key: 'time', width: 70 },
  { title: '状态', key: 'state', width: 70 },
  { title: '信息', key: 'info', width: 200 },
])

const dblistData = ref<Awaited<ReturnType<typeof getLastDbProcessList>>>([])

const loadingDbList = ref(false)
const refreshDbProcessList = async () => {
  loadingDbList.value = true
  try {
    dblistData.value = await getLastDbProcessList(id.value)
  } catch (error) {
    console.error(error)
  } finally {
    loadingDbList.value = false
  }
}

// 添加一个函数来加载所有数据
const loadAllData = async () => {
  // 创建所有图表管理器实例
  chartManager.value = new ChartManager('memory-chart', id.value)
  diskChartManager.value = new DiskChartManager('disk-chart', id.value)
  cpuChartManager.value = new CpuChartManager('cpu-chart', id.value)
  dbProcessChartManager.value = new DbProcessChartManager('db-process-chart', id.value)
  networkInChartManager.value = new NetworkChartManager('network-in-chart', id.value, true)
  networkOutChartManager.value = new NetworkChartManager('network-out-chart', id.value, false)

  // 并发加载所有数据
  await Promise.all([
    chartManager.value.initLoad(),
    diskChartManager.value.initLoad(),
    cpuChartManager.value.initLoad(),
    dbProcessChartManager.value.initLoad(),
    networkInChartManager.value.initLoad(),
    networkOutChartManager.value.initLoad(),
    fetchRunningBackendProcesses(id.value).then(res => data.value = res),
    getLastDbProcessList(id.value).then(res => dblistData.value = res)
  ])
}

// 监听路由参数变化
watch(
  () => route.params.id,
  async (newId) => {
    if (newId) {
      id.value = newId as string
      currentHost.value = newId as string
      await loadAllData()
    }
  }
)

// 修改 onMounted
onMounted(async () => {
  await nextTick()
  await loadAllData()
})

const loading = ref(false)
const refreshBackendProcesses = async () => {
  loading.value = true
  try {
    data.value = await fetchRunningBackendProcesses(id.value)
  } catch (error) {
    console.error(error)
  } finally {
    loading.value = false
  }
}

const addProcess = async () => {
  try {
    if (isUpdate.value) {
      await editProcess()
    } else {
      newBackendProcess.value.envs = envList.value.reduce((acc, cur) => {
        acc[cur.key] = cur.value
        return acc
      }, {} as Record<string, string>)
      console.log(newBackendProcess.value)

      await addBackendProcess(newBackendProcess.value)
      data.value = await fetchRunningBackendProcesses(id.value)
    }
  } catch (error) {
    console.error(error)
  } finally {
    envList.value = [{key: '', value: ''}]
    isUpdate.value = false
    showAddBackendProcessModal.value = false
  }
}

const editProcess = async () => {
  newBackendProcess.value.envs = envList.value.reduce((acc, cur) => {
    acc[cur.key] = cur.value
    return acc
  }, {} as Record<string, string>)
  await updateBackendProcess(newBackendProcess.value)
  data.value = await fetchRunningBackendProcesses(id.value)
}

const cancel = () => {
  isUpdate.value = false
  newBackendProcess.value = {
    id: 0,
    host_name: id.value,
    process_name: '',
    program: '',
    args: [],
    auto_restart: true,
    max_restarts: 0,
    restart_delay: 0,
    envs: {},
    user: '',
    cwd: '',
    dependencies: [],
    exponential_backoff: false,
  }
  showAddBackendProcessModal.value = false
}
</script>

<style scoped></style>