<template>
    <n-grid :cols="24" :x-gap="8" :y-gap="8">
        <n-grid-item :span="deviceColumns" v-for="item in systemData" :key="item.id">
            <n-card :title="item.host_name" hoverable class="system-card">
                <n-space vertical :size="8">
                    <span class="text-lg font-medium">统计</span>
                    <div class="flex flex-col items-center gap-2">
                        <div class="w-full">
                            <h2 class="text-base mb-2">Memory</h2>
                            <n-tooltip placement="bottom">
                                <template #trigger>
                                    <n-progress 
                                        type="circle" 
                                        :percentage="formatPercentage(item.data.memory.used, item.data.memory.total)"
                                        :height="deviceColumns === 24 ? 80 : 100"
                                    />
                                </template>
                                <div class="text-sm">
                                    <div>总量: {{ (item.data.memory.total / 1024 / 1024).toFixed(2) }} MB</div>
                                    <div>已用: {{ (item.data.memory.used / 1024 / 1024).toFixed(2) }} MB</div>
                                    <div>可用: {{ (item.data.memory.available / 1024 / 1024).toFixed(2) }} MB</div>
                                </div>
                            </n-tooltip>
                        </div>
                        <div v-for="disk in item.data.disks" :key="disk.name" class="w-full">
                            <h2 class="text-base mb-2">Disk {{ disk.name }}</h2>
                            <n-tooltip placement="bottom">
                                <template #trigger>
                                    <n-progress 
                                        :percentage="formatPercentage(disk.used_space, disk.total_space)"
                                        :height="16"
                                    />
                                </template>
                                <div class="text-sm">
                                    <div>总量: {{ (disk.total_space / 1024 / 1024 / 1024).toFixed(2) }} GB</div>
                                    <div>已用: {{ (disk.used_space / 1024 / 1024 / 1024).toFixed(2) }} GB</div>
                                    <div>可用: {{ (disk.available_space / 1024 / 1024 / 1024).toFixed(2) }} GB</div>
                                    <div>挂载点: {{ disk.mount_point }}</div>
                                </div>
                            </n-tooltip>
                        </div>
                        <div class="w-full">
                            <h2 class="text-base mb-2">CPU</h2>
                            <div class="text-sm">
                                <p>CPU数量: {{ item.data.cpu.length }}</p>
                                <p>使用率>50%: {{ item.data.cpu.filter((cpu: any) => cpu.usage > 50).length }}</p>
                            </div>
                        </div>
                        <div class="w-full">
                            <h2 class="text-base mb-2">数据库</h2>
                            <p class="text-sm">进程数量: {{ item.data.db_processlist.length }}</p>
                        </div>
                    </div>
                    <n-space justify="end" class="mt-2">
                        <n-button type="primary" size="small" @click="handleViewDetail(item)">
                            查看详情
                        </n-button>
                    </n-space>
                </n-space>
            </n-card>
        </n-grid-item>
    </n-grid>
</template>

<script setup lang="ts">
import { getIndexSystemData, type SystemData } from '@/api/system-data.api'
import { ref, onMounted, computed } from 'vue'
import { useWindowSize } from '@vueuse/core'
import { NGrid, NGridItem, NCard, NSpace, NButton, NTooltip, NProgress } from 'naive-ui'
import router from "@/router";
import { useAuthStore } from '@/store/auth.store';

const systemData = ref<SystemData[]>([])
const { width } = useWindowSize()

// 根据窗口宽度计算每个卡片应该占据的列数
const deviceColumns = computed(() => {
    if (width.value < 640) return 24        // 小屏幕一行显示1个
    if (width.value < 1024) return 12       // 中等屏幕一行显示2个
    if (width.value < 1280) return 8        // 大屏幕一行显示3个
    return 6                                // 超大屏幕一行显示4个
})

const handleViewDetail = (item: SystemData) => {
    router.push(`/detail/${item.host_name}`)
}

const formatPercentage = (value: number, total: number) => {
    const percentage = value / total * 100
    return parseFloat(percentage.toFixed(2))
}

onMounted(async () => {
    const data = await getIndexSystemData()
    systemData.value = data
    const authStore = useAuthStore()
    authStore.setHosts(data.map((item: SystemData) => item.host_name))
    authStore.persistToken()
})
</script>

<style scoped>
.system-card {
    height: 100%;
}

.system-card :deep(.n-card__content) {
    padding: 8px;
}

@media (max-width: 640px) {
    .system-card :deep(.n-card__content) {
        padding: 8px;
    }
    
    .system-card :deep(.n-card-header) {
        padding: 8px;
    }
    
    .system-card :deep(.n-card__title) {
        font-size: 16px;
    }
}
</style>