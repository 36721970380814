import axios, {AxiosError} from "axios";
import {useAuthStore} from "@/store/auth.store";
import {getApiBaseUrl} from '@/config/api.config'

type MetricResponse = {
    value: number
    timestamp: string
}

type DiskResponse = MetricResponse & {
    name: string
    mount_point: string
}

type NetworkResponse = MetricResponse & {
    mac_address: string
}

// 获取基础URL
const baseUrl = getApiBaseUrl()

/**
 * 获取指定服务器的内存使用率
 * @param host_name
 * @param start_time
 * @param end_time
 * @param interval
 */
export const fetchMemoryMetrics = async (host_name: string, start_time: string, end_time: string, interval: string): Promise<MetricResponse[]> => {
    const authStore = useAuthStore()

    if (!authStore.isLoggedIn) {
        throw new Error('Not logged in')
    }

    try {
        const response = await axios.get<MetricResponse[]>(`${baseUrl}/system/memory`, {
            headers: {
                Authorization: `Bearer ${authStore.token}`
            },
            params: {
                host_name,
                start_time,
                end_time,
                interval
            }
        })
        return response.data
    } catch (error) {
        const status = (error as AxiosError).response?.status ?? 500
        if (status === 401) {
            await authStore.logout();
        }
        throw new Error(`Failed to fetch metrics: ${status}`)
    }
}

type DiskResponses = [DiskResponse[], DiskResponse[]]

export const fetchDiskMetrics = async (host_name: string, start_time: string, end_time: string, interval: string): Promise<DiskResponses> => {
    const authStore = useAuthStore()

    if (!authStore.isLoggedIn) {
        throw new Error('Not logged in')
    }

    try {
        const response = await axios.get<DiskResponses>(`${baseUrl}/system/disk`, {
            headers: {
                Authorization: `Bearer ${authStore.token}`
            },
            params: {
                host_name,
                start_time,
                end_time,
                interval
            }
        })
        return response.data
    } catch (error) {
        const status = (error as AxiosError).response?.status ?? 500
        if (status === 401) {
            await authStore.logout();
        }
        throw new Error(`Failed to fetch metrics: ${status}`)
    }
}

type NetworkInOutResponse = Array<NetworkResponse>

export const fetchNetworkInMetrics = async (host_name: string, start_time: string, end_time: string, interval: string): Promise<NetworkInOutResponse> => {
    const authStore = useAuthStore()
    if (!authStore.isLoggedIn) {
        throw new Error('Not logged in')
    }

    try {
        const response = await axios.get<NetworkInOutResponse>(`${baseUrl}/system/net-in`, {
            headers: {
                Authorization: `Bearer ${authStore.token}`
            },
            params: {
                host_name,
                start_time,
                end_time,
                interval
            }
        })
        return response.data
    } catch (error) {
        const status = (error as AxiosError).response?.status ?? 500
        if (status === 401) {
            await authStore.logout();
        }
        throw new Error(`Failed to fetch metrics: ${status}`)
    }
}

export const fetchNetworkOutMetrics = async (host_name: string, start_time: string, end_time: string, interval: string): Promise<NetworkInOutResponse> => {
    const authStore = useAuthStore()
    if (!authStore.isLoggedIn) {
        throw new Error('Not logged in')
    }

    try {
        const response = await axios.get<NetworkInOutResponse>(`${baseUrl}/system/net-out`, {
            headers: {
                Authorization: `Bearer ${authStore.token}`
            },
            params: {
                host_name,
                start_time,
                end_time,
                interval
            }
        })
        return response.data
    } catch (error) {
        const status = (error as AxiosError).response?.status ?? 500
        if (status === 401) {
            await authStore.logout();
        }
        throw new Error(`Failed to fetch metrics: ${status}`)
    }
}

type CpuResponse = {
    timestamp: string
    one_minute: number
    five_minutes: number
    fifteen_minutes: number
}

export const fetchCpuMetrics = async (host_name: string, start_time: string, end_time: string, interval: string): Promise<CpuResponse[]> => {
    const authStore = useAuthStore()

    if (!authStore.isLoggedIn) {
        throw new Error('Not logged in')
    }

    try {
        const response = await axios.get<CpuResponse[]>(`${baseUrl}/system/cpu`, {
            headers: {
                Authorization: `Bearer ${authStore.token}`
            },
            params: {
                host_name,
                start_time,
                end_time,
                interval
            }
        })
        return response.data
    } catch (error) {
        const status = (error as AxiosError).response?.status ?? 500
        if (status === 401) {
            await authStore.logout();
        }
        throw new Error(`Failed to fetch metrics: ${status}`)
    }
}

export const fetchDbProcesses = async (host_name: string, start_time: string, end_time: string, interval: string): Promise<MetricResponse[]> => {
    const authStore = useAuthStore()
    if (!authStore.isLoggedIn) {
        throw new Error('Not logged in')
    }
    try {
        const response = await axios.get<MetricResponse[]>(`${baseUrl}/system/db-processlist`, {
            headers: {
                Authorization: `Bearer ${authStore.token}`
            },
            params: {
                host_name,
                start_time,
                end_time,
                interval
            }
        })
        return response.data
    } catch (error) {
        const status = (error as AxiosError).response?.status ?? 500
        if (status === 401) {
            await authStore.logout();
        }
        throw new Error(`Failed to fetch metrics: ${status}`)
    }
}

// ---------------- 后台任务  ---------------
type RunningBackendProcess = {
    id: number
    process_name: string
    host_name: string
    running: boolean
    restart_count: number
    last_exit_code: number|null
    last_error: string|null
    last_restart_at: string|null
    program: string
    args: string[]
    auto_restart: boolean
    max_restarts: number
    restart_delay: number
    envs: Record<string, string>
    user: string
    cwd: string
    dependencies: string[]
    exponential_backoff: boolean
    created_at: string
    last_exited_at: string|null
    started_at: string
}
export const fetchRunningBackendProcesses = async (host_name: string): Promise<RunningBackendProcess[]> => {
    const authStore = useAuthStore()
    if (!authStore.isLoggedIn) {
        throw new Error('Not logged in')
    }

    try {
        const response = await axios.get<RunningBackendProcess[]>(`${baseUrl}/system/processes`, {
            headers: {
                Authorization: `Bearer ${authStore.token}`
            },
            params: {
                host_name
            }
        })
        return response.data
    } catch (error) {
        const status = (error as AxiosError).response?.status ?? 500
        if (status === 401) {
            await authStore.logout();
        }
        throw new Error(`Failed to fetch metrics: ${status}`)
    }
} 

export const addBackendProcess = async (args: Omit<RunningBackendProcess, 'id' | 'running' | 'last_error' | 'last_exit_code' | 'last_restart_at' | 'created_at' | 'restart_count' | 'last_exited_at' | 'started_at'>) => {
    const authStore = useAuthStore()
    if (!authStore.isLoggedIn) {
        throw new Error('Not logged in')
    }

    try {
        const response = await axios.post(`${baseUrl}/system/processes`, args, {
            headers: {
                Authorization: `Bearer ${authStore.token}`
            }
        })
        return response.data
    } catch (error) {
        const status = (error as AxiosError).response?.status ?? 500
        if (status === 401) {
            await authStore.logout();
        }
        throw new Error(`Failed to add backend process: ${status}`)
    }
}

export const deleteBackendProcess = async (host_name: string, process_name: string, id: number) => {
    const authStore = useAuthStore()
    if (!authStore.isLoggedIn) {
        throw new Error('Not logged in')
    }

    try {
        const response = await axios.delete(`${baseUrl}/system/processes/${id}`, {
            headers: {
                Authorization: `Bearer ${authStore.token}`
            },
            params: {
                host_name,
                process_name
            }
        })
        return response.data
    } catch (error) {
        const status = (error as AxiosError).response?.status ?? 500
        if (status === 401) {
            await authStore.logout();
        }
        throw new Error(`Failed to delete backend process: ${status}`)
    }
}

export const updateBackendProcess = async (args: Omit<RunningBackendProcess, 'running' | 'last_error' | 'last_exit_code' | 'last_restart_at' | 'created_at' | 'restart_count' | 'last_exited_at' | 'started_at'>) => {
    const authStore = useAuthStore()
    if (!authStore.isLoggedIn) {
        throw new Error('Not logged in')
    }

    try {
        const response = await axios.put(`${baseUrl}/system/processes/${args.id}`, args, {
            headers: {
                Authorization: `Bearer ${authStore.token}`
            },
        })
        return response.data
    } catch (error) {
        const status = (error as AxiosError).response?.status ?? 500
        if (status === 401) {
            await authStore.logout();
        }
        throw new Error(`Failed to update backend process: ${status}`)
    }
}

export const restartBackendProcess = async (host_name: string, process_name: string) => {
    const authStore = useAuthStore()
    if (!authStore.isLoggedIn) {
        throw new Error('Not logged in')
    }

    try {
        const response = await axios.post(`${baseUrl}/system/processes/restart`, {
            host_name,
            process_name
        }, {
            headers: {
                Authorization: `Bearer ${authStore.token}`
            },
        })
        return response.data
    } catch (error) {
        const status = (error as AxiosError).response?.status ?? 500
        if (status === 401) {
            await authStore.logout();
        }
        throw new Error(`Failed to restart backend process: ${status}`)
    }
}

export const stopBackendProcess = async (host_name: string, process_name: string) => {
    const authStore = useAuthStore()
    if (!authStore.isLoggedIn) {
        throw new Error('Not logged in')
    }

    try {
        const response = await axios.post(`${baseUrl}/system/processes/stop`, {
            host_name,
            process_name
        }, {
            headers: {
                Authorization: `Bearer ${authStore.token}`
            },
        })
        return response.data
    } catch (error) {
        const status = (error as AxiosError).response?.status ?? 500
        if (status === 401) {
            await authStore.logout();
        }
        throw new Error(`Failed to stop backend process: ${status}`)
    }
}

export const startBackendProcess = async (host_name: string, process_name: string) => {
    const authStore = useAuthStore()
    if (!authStore.isLoggedIn) {
        throw new Error('Not logged in')
    }

    try {
        const response = await axios.post(`${baseUrl}/system/processes/start`, {
            host_name,
            process_name
        }, {
            headers: {
                Authorization: `Bearer ${authStore.token}`
            },
        })
        return response.data
    } catch (error) {
        const status = (error as AxiosError).response?.status ?? 500
        if (status === 401) {
            await authStore.logout();
        }
        throw new Error(`Failed to start backend process: ${status}`)
    }
}