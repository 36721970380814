import * as echarts from 'echarts'
import type { EChartsOption } from 'echarts'
import { fetchNetworkInMetrics, fetchNetworkOutMetrics } from "@/api/detail.api"
import { formatDateTime } from './time.utils'
import { debounce, type DebouncedFunc } from 'lodash'

interface NetworkData {
    name: string
    mac_address: string
    data: [number, number][]
}

export class NetworkChartManager {
    private chart: echarts.ECharts
    private loading: boolean
    private hostName: string
    private currentData: Map<string, NetworkData> = new Map() // key: mac_address_type (rx/tx)
    private currentRange = {
        start: new Date(),
        end: new Date()
    }
    private debouncedLoadData: DebouncedFunc<(start: Date, end: Date) => void>
    private colors = ['#5470c6', '#91cc75']

    constructor(elementId: string, hostname: string, private isIn: boolean) {
        const element = document.getElementById(elementId)
        if (!element) throw new Error('element not found')
        
        this.chart = echarts.init(element)
        this.loading = false
        this.hostName = hostname
        this.debouncedLoadData = debounce(this.loadData.bind(this), 500)
        this.initChart()
    }

    private formatBytes(bytes: number): number {
        return Number((bytes / (1024 * 1024 * 1024)).toFixed(2)) // 转换为 GB 并保留两位小数
    }

    private calculateYAxisRange(series: any[]): { min: number; max: number } {
        let min = Infinity
        let max = -Infinity

        series.forEach(serie => {
            serie.data.forEach((point: [number, number]) => {
                min = Math.min(min, point[1])
                max = Math.max(max, point[1])
            })
        })

        if (min === Infinity || max === -Infinity) {
            return { min: 0, max: 100 }
        }

        const range = max - min
        min = Math.max(0, min - range * 0.1)
        max = max + range * 0.1

        return { min, max }
    }

    private initChart() {
        const option: EChartsOption = {
            color: this.colors,
            tooltip: {
                trigger: 'axis',
                formatter: (params: any) => {
                    if (Array.isArray(params)) {
                        const time = new Date((params[0].value as number[])[0]).toUTCString()
                        let result = `${time}<br/>`
                        params.forEach(param => {
                            const value = (param.value as number[])[1]
                            result += `${param.marker}${param.seriesName}: ${value} GB<br/>`
                        })
                        return result
                    }
                    return ''
                }
            },
            legend: {
                data: [],
                type: 'scroll',
                orient: 'horizontal',
                top: 0
            },
            grid: {
                top: 50,
                right: 20,
                bottom: 60,
                left: 80
            },
            xAxis: {
                type: 'time',
                splitLine: {
                    show: false,
                }
            },
            yAxis: {
                type: 'value',
                name: `${this.isIn ? '入站' : '出站'}流量(GB)`,
                splitNumber: 6,
                splitLine: {
                    show: true
                },
                axisLabel: {
                    formatter: (value: number) => {
                        if (value >= 1000) {
                            return (value / 1000).toFixed(1) + 'K'
                        }
                        return value.toFixed(1)
                    }
                }
            },
            dataZoom: [
                {
                    type: 'inside',
                    start: 0,
                    end: 100,
                    minSpan: 1
                },
                {
                    type: 'slider',
                    start: 0,
                    end: 100,
                    minSpan: 1
                }
            ],
            series: []
        }

        this.chart.setOption(option)

        this.chart.on('datazoom', (params: any) => {
            if (this.loading || !this.chart || this.currentData.size === 0) return

            const dataTimeRange = this.getDataTimeRange()
            const option = this.chart.getOption()
            const dataZoom = option.dataZoom as any[]
            if (!dataZoom || !dataZoom[0]) return

            const { start, end } = dataZoom[0]
            if (typeof start !== 'number' || typeof end !== 'number') return

            const timeRange = dataTimeRange.end - dataTimeRange.start
            const startTime = dataTimeRange.start + (timeRange * start / 100)
            const endTime = dataTimeRange.start + (timeRange * end / 100)

            if (this.shouldLoadNewData(startTime, endTime)) {
                this.debouncedLoadData(new Date(startTime), new Date(endTime))
            } else {
                this.updateVisibleData(startTime, endTime)
            }
        })
    }

    private getDataTimeRange() {
        let start = Date.now()
        let end = 0

        for (const networkData of this.currentData.values()) {
            if (networkData.data.length > 0) {
                start = Math.min(start, networkData.data[0][0])
                end = Math.max(end, networkData.data[networkData.data.length - 1][0])
            }
        }

        return { start, end }
    }

    private updateVisibleData(startTime: number, endTime: number) {
        if (!this.chart) return

        const series: any[] = []
        const legendData: string[] = []

        this.currentData.forEach((networkData, key) => {
            const visibleData = networkData.data.filter(point =>
                point[0] >= startTime && point[0] <= endTime
            )

            const displayName = `${networkData.mac_address} (${this.isIn ? '入站' : '出站'})`
            legendData.push(displayName)

            series.push({
                name: displayName,
                type: 'line',
                showSymbol: false,
                data: visibleData,
                emphasis: {
                    focus: 'series'
                },
                areaStyle: {
                    opacity: 0.1
                }
            })
        })

        const { min, max } = this.calculateYAxisRange(series)

        this.chart.setOption({
            legend: {
                data: legendData
            },
            yAxis: {
                min,
                max,
                type: 'value',
                name: `${this.isIn ? '入站' : '出站'}流量(GB)`,
                splitNumber: 6,
                axisLabel: {
                    formatter: (value: number) => {
                        if (value >= 1000) {
                            return (value / 1000).toFixed(1) + 'K'
                        }
                        return value.toFixed(1)
                    }
                }
            },
            series: series
        })
    }

    private shouldLoadNewData(startTime: number, endTime: number): boolean {
        const currentStart = this.currentRange.start.getTime()
        const currentEnd = this.currentRange.end.getTime()
        return !(startTime >= currentStart && endTime <= currentEnd)
    }

    private calculateInterval(start: Date, end: Date): string {
        const hours = (end.getTime() - start.getTime()) / (1000 * 60 * 60)
        if (hours > 24 * 7) return '1hour'
        if (hours > 24) return '30min'
        if (hours > 6) return '5min'
        if (hours > 1) return '1min'
        return '5sec'
    }

    async loadData(start: Date, end: Date) {
        if (this.loading || !start || !end || isNaN(start.getTime()) || isNaN(end.getTime())) {
            return
        }

        try {
            this.loading = true
            if (this.chart) {
                this.chart.showLoading()
            }

            const interval = this.calculateInterval(start, end)
            const fn = this.isIn ? fetchNetworkInMetrics : fetchNetworkOutMetrics
            const data = await fn(
                this.hostName,
                formatDateTime(start),
                formatDateTime(end),
                interval
            )

            const groupedData = new Map<string, NetworkData>()
            
            // 直接处理累计流量数据
            data.forEach(item => {
                if (!item.mac_address) return
                
                if (!groupedData.has(item.mac_address)) {
                    groupedData.set(item.mac_address, {
                        name: this.isIn ? '入站流量' : '出站流量',
                        mac_address: item.mac_address,
                        data: []
                    })
                }
                
                const networkData = groupedData.get(item.mac_address)!
                networkData.data.push([
                    new Date(item.timestamp).getTime(),
                    this.formatBytes(item.value) // 转换为 GB
                ])
            })

            // 对每个网卡的数据按时间排序
            groupedData.forEach(networkData => {
                networkData.data.sort((a, b) => a[0] - b[0])
            })

            // 合并新旧数据
            groupedData.forEach((newNetworkData, mac_address) => {
                const existingData = this.currentData.get(mac_address)
                if (existingData) {
                    const allData = [...existingData.data, ...newNetworkData.data]
                    newNetworkData.data = Array.from(new Map(allData.map(item => [item[0], item])).values())
                        .sort((a, b) => a[0] - b[0])
                }
                this.currentData.set(mac_address, newNetworkData)
            })

            this.updateVisibleData(start.getTime(), end.getTime())
            this.currentRange = { start, end }

        } catch (error) {
            console.error('Error loading network data:', error)
        } finally {
            this.loading = false
            if (this.chart) {
                this.chart.hideLoading()
            }
        }
    }

    async initLoad() {
        const end = new Date()
        const start = new Date(end.getTime() - 24 * 60 * 60 * 1000)
        await this.loadData(start, end)
    }

    resize() {
        if (this.chart) {
            this.chart.resize()
        }
    }

    dispose() {
        if (this.debouncedLoadData) {
            this.debouncedLoadData.cancel()
        }
        if (this.chart) {
            this.chart.dispose()
            this.chart = null as any
        }
    }
}
