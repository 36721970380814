import { defineStore } from "pinia";
import router from "@/router";

type AuthState = {
    token: string | null
    expires: string | null
    username: string | null
    hosts: string[]
}

export const useAuthStore = defineStore('auth', {
    state: (): AuthState => {
        const token = localStorage.getItem('token') || null
        const expires = localStorage.getItem('expires') || null
        const username = localStorage.getItem('username') || null
        const hosts = JSON.parse(localStorage.getItem('hosts') || '[]')
        return { token, expires, username, hosts }
    },
    getters: {
        isLoggedIn: (state) => state.token !== null,
    },
    actions: {
        setHosts(hosts: string[]) {
            this.hosts = hosts
        },
        setToken(token: string, expires: string, username: string) {
            this.token = token
            this.expires = expires
            this.username = username

            this.persistToken();
        },
        persistToken() {
            localStorage.setItem('token', this.token ?? '')
            localStorage.setItem('expires', this.expires ?? '')
            localStorage.setItem('username', this.username ?? '')
            localStorage.setItem('hosts', JSON.stringify(this.hosts))
        },
        async logout() {
            this.token = null
            this.expires = null
            this.username = null
            this.hosts = []

            localStorage.removeItem('token')
            localStorage.removeItem('expires')
            localStorage.removeItem('username')
            localStorage.removeItem('hosts')
            await router.push('/login')
        }
    },
})
